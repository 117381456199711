/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

// Libs
import { toast } from "react-toastify";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius,
  defaultPaletteValue
} from "components/ThermalManipulation/constants";

// Components
import Gallery from "./components/Gallery";
import DiagnosisSection from "./components/DiagnosisSection";
import ModalConfirm from "./components/ModalConfirm";
import ThermalManipulation from "components/ThermalManipulation";
import GenericModal from "components/GenericModal";

//Icons
import DoubleArrow from "assets/double-arrow.svg";

import { LeftContainer, MiddleContainer, RightContainer, ActionIconLeft, ActionIconRight, ArrowContainer } from "./styles";

import { PNG } from 'pngjs/browser';

const MIN_TEMPERATURE_ADJUSTMENT = -2;
const MAX_TEMPERATURE_ADJUSTMENT = 2;


function ModalDiagnosis({
  setShowModal,
  group,
  handleGetImageByID,
  handleGetAnalysedImageInfo,
  handleGetModalInfo,
  groupAnalysedImages,
  screeningId,
}) {
  const { setLoading, isAdmin, globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);

  const [addedImagesIds, setAddedImagesIds] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState(null);
  const [toggleComponent, setToggleComponent] = useState(true);
  const [paletteOptions, setpaletteOptions] = useState([]);
  const [savedImage, setSavedImage] = useState(null);
  const [isViewingSavedImage, setIsViewingSavedImage] = useState(null);
  const [isClosingDiagnosis, setIsClosingDiagnosis] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isGalleryLeft, setIsGalleryLeft] = useState(true);
  const [isShowingDeltaCalc, setIsShowingDeltaCalc] = useState(false);
  const [formRect, setFormRect] = useState('circle');
  const [isSelectedForm, setIsSelectedForm] = useState(false);
  const [isDiagnosisRight, setIsDiagnosisRight] = useState(true);
    
  const [deltas, setDeltas] = useState([])

  const [state, setState] = useState({
    images: [],
    temperatures: [],
    minTemperature: null,
    maxTemperature: null,
    userSelectedMinTemperature: null,
    userSelectedMaxTemperature: null,
    userSelectedPallete: defaultPaletteValue
  })

  const trRef = React.useRef();

  useEffect(() => {
    handleGetInfoDiagnosis();
  }, []);

  useEffect(() => {
    handleAddedImagesIds();
    updateImagesOnCanvas();
  }, [state.userSelectedMinTemperature, state.userSelectedMaxTemperature, state.userSelectedPallete]);

  const handleGetInfoDiagnosis = () => {
    setLoading(true);

    Promise.all([
      userPassService
        .getListGroupDiagnostics(group?.groupId)
        .then((o) => {
          if (o.data) {
            setDiagnosisData(o.data);
          }
        })
        .catch(() => {
          toast.error("Falha ao carregar dados de diagnóstico");
        }),
      userPassService
        .getScientificImagePalletes()
        .then((o) => {
          if (o.data) {
            setpaletteOptions(o.data);
          }
        })
        .catch(() => {
          toast.error("Falha ao carregar paletas");
        }),
    ])
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const updateListGroupDiagnostics = (body) => {
    setLoading(true);

    userPassService
      .updateListGroupDiagnostics({ groupId: group.groupId, ...body })
      .then(() => {
        toast.success("Diagnóstico salvo com sucesso");
        handleGetModalInfo();
      })
      .catch(() => {
        setLoading(false);
        toast.error("Falha ao salvar diagnóstico");
      });
  };

  const handleImageClick = ({ imageUrl, imageId, isAnalysed }) => {
    if (state.images.length >= 16) {
        toast.warning("Limite máximo de 16 imagens por análise");
        return;
    }
    handleClearCanvaTransformSelection();
    const imageKey = `${imageId}_${state.images.filter(image => image.imageId === imageId).length}`;

    setLoading(true);

    const selectedPalette = state.userSelectedPallete

    const palleteRgb = selectedPalette.palleteRgb;
    const palleteName = selectedPalette.nameKey;
    const serverWindowingMin = state.userSelectedMinTemperature === null ? minTemperatureCelsius : state.userSelectedMinTemperature
    const serverWindowingMax = state.userSelectedMaxTemperature === null ? maxTemperatureCapCelsius : state.userSelectedMaxTemperature

    userPassService
        .getManipulatedDetailedImageBase64(imageUrl, palleteName, serverWindowingMin, serverWindowingMax)
        .then((o) => {
            if (o.data) {
                addNewImageToCanvas({ imageData: o.data, imageUrl, imageId: imageId, imageKey, isAnalysed, pallete: palleteRgb});
            }
        })
        .catch((ex) => {
            console.log(ex);
            toast.error("Falha ao carregar imagem");
        })
        .finally(() => {
            setLoading(false);
            setToggleComponent(true);
        });
  };

  const handleClearCanvaTransformSelection = () => {
      if (trRef.current) { 
          trRef.current.nodes([]);
      }
  };

  const addNewImageToCanvas = ({ imageData, imageUrl, imageId, imageKey, isAnalysed, pallete}) => {
    
    const userWindowingMin = state.userSelectedMinTemperature
    const userWindowingMax = state.userSelectedMaxTemperature


    const pngImage = applyColorMap(imageData.temperatures, pallete, userWindowingMin, userWindowingMax);

    const buffer = PNG.sync.write(pngImage.values);
    const dataURL = 'data:image/png;base64,' + buffer.toString('base64');

    const image = new Image();
    image.src = dataURL;

    const minImageTemperature = pngImage["minValue"];
    const maxImageTemperature = pngImage["maxValue"];

    const minTemperature = Math.max(minImageTemperature + MIN_TEMPERATURE_ADJUSTMENT, 0);
    const maxTemperature = maxImageTemperature + MAX_TEMPERATURE_ADJUSTMENT;

    var updatedState = {
      ...state,
      minTemperature: state.minTemperature === null || minTemperature < state.minTemperature ? minTemperature : state.minTemperature,
      maxTemperature: state.maxTemperature === null || maxTemperature > state.maxTemperature ? maxTemperature : state.maxTemperature,
      userSelectedMinTemperature: state.userSelectedMinTemperature == null ? minImageTemperature : state.userSelectedMinTemperature,
      userSelectedMaxTemperature: state.userSelectedMaxTemperature == null ? maxImageTemperature : state.userSelectedMaxTemperature
    }

    image.onload = () => {
        updatedState = {
          ...updatedState,
          temperatures: [...state.temperatures, { values: imageData.temperatures }],
          images: [...state.images, { imageData: image, imageUrl, imageId, imageKey, isAnalysed, imageTempRanges: [updatedState.minTemperature, updatedState.maxTemperature] }]
        };
        setState(updatedState)
    };
  };

  const updateImagesOnCanvas = () => {
    if (state.images && state.images.length > 0) {
      

      const operatedImages = state.images.map(image => ({ ...image }));

      var completed = 0

      state.images.forEach((imageOnCanvas, index) => {
          setLoading(true);

          const selectedPalette = state.userSelectedPallete;

          const pallete = selectedPalette.palleteRgb;
          const windowingMin = state.userSelectedMinTemperature || null;
          const windowingMax = state.userSelectedMaxTemperature || null;
          const temperatures = state.temperatures[index].values;

          const pngImage = applyColorMap(temperatures, pallete, windowingMin, windowingMax);

          const buffer = PNG.sync.write(pngImage.values);
          const dataURL = 'data:image/png;base64,' + buffer.toString('base64');

          const image = new Image();
          image.src = dataURL;
          
          const range = [windowingMin, windowingMax];

          image.onload = () => {
            operatedImages[index] = { ...imageOnCanvas, imageData: image, imageTempRanges: range }
            completed++;

            if (completed == state.images.length) {
              setState({
                ...state,
                images: operatedImages
              })
            }
          };

      });      

      setLoading(false);
      setToggleComponent(true);
    }
  };

  const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

  const rgbToHex = (rgb) => {
      return "#" + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
  }

  const clipMatrix = (matrix, min, max) => {
    var numRows = matrix.length;
    var numCols = matrix[0].length;
    var clippedMatrix = new Array(numRows);
    
    for (var i = 0; i < numRows; i++) {
        clippedMatrix[i] = new Array(numCols);
        for (var j = 0; j < numCols; j++) {
            clippedMatrix[i][j] = clip(matrix[i][j], min, max);
        }
    }
    
    return clippedMatrix;
  }

  const clip = (value, min, max) => {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    } else {
        return value;
    }
  }

  const applyColorMap = (originalTemperatures, colorMap, selectedMinTemperature, selectedMaxTemperature) => {

    var colorMap = colorMap.map(row => [...row]);
    colorMap.reverse();

    var temperatures = originalTemperatures.map(row => row.map(temp => temp));

    var numRows = temperatures.length;
    var numCols = temperatures[0].length;
    
    // var svgString = '<svg xmlns="http://www.w3.org/2000/svg" width="' + numCols + '" height="' + numRows + '">';

    const png = new PNG({
      width: numCols,
      height: numRows,
      filterType: -1 // No filtering
  });

    var originalMinValue = Math.min(...temperatures.map(row => Math.min(...row)));
    var originalMaxValue = Math.max(...temperatures.map(row => Math.max(...row)));

    if (selectedMinTemperature != null && selectedMaxTemperature != null) {
      temperatures = clipMatrix(temperatures, selectedMinTemperature, selectedMaxTemperature);

      var minValue = Math.min(Math.min(...temperatures.map(row => Math.min(...row))), selectedMinTemperature);
      var maxValue = Math.max(Math.max(...temperatures.map(row => Math.max(...row))), selectedMaxTemperature);
    } else {
      var minValue = Math.min(...temperatures.map(row => Math.min(...row)));
      var maxValue = Math.max(...temperatures.map(row => Math.max(...row)));
    }

    var range = maxValue - minValue;

    // var squareSize = 1;
    // var minProcessed = true;
    // var maxProcessed = true;
    for (var i = 0; i < numRows; i++) {
        for (var j = 0; j < numCols; j++) {
            const index = (numCols * i + j) << 2;

            var pixelTemperature = temperatures[i][j];

            // if (!minProcessed && pixelTemperature == originalMaxValue) {
            //   pixelTemperature = pixelTemperature + MAX_TEMPERATURE_ADJUSTMENT;
            // } else if(!maxProcessed && pixelTemperature == originalMinValue) {
            //   pixelTemperature = Math.max(pixelTemperature + MIN_TEMPERATURE_ADJUSTMENT, 0);
            // }

            var normalizedValue = (pixelTemperature - minValue) / range;
            var colorIndex = Math.floor(normalizedValue * (colorMap.length - 1));

            var colorRgb = colorMap[colorIndex];

            png.data[index] = colorRgb[0];
            png.data[index + 1] = colorRgb[1];
            png.data[index + 2] = colorRgb[2];
            png.data[index + 3] = 255


            // var color = rgbToHex(colorMap[colorIndex]);
            // svgString += '<rect y="' + (i * squareSize) + '" x="' + (j * squareSize) + '" width="' + squareSize + '" height="' + squareSize + '" fill="' + color + '"  fill-opacity="1"/>';
        }
    }

    // svgString += '</svg>';
    return {"values": png, minValue: originalMinValue, maxValue: originalMaxValue};
  };

  const handleAddedImagesIds = () => {
    const auxAddedIds = [];
    state.images.forEach((image) => {
        if (!auxAddedIds.includes(image.imageId)) {
            auxAddedIds.push(image.imageId);
        }
    });
    setAddedImagesIds(auxAddedIds);
  };

  const handleOnUserSelectedRangeValue = (range) => {
     setState({
      ...state,
      userSelectedMinTemperature: range[0],
      userSelectedMaxTemperature: range[1]
    });
  }

  const handleOnClear = () => {
    setState({
      ...state,
      images: [],
      temperatures: [],
      minTemperature: null,
      maxTemperature: null,
      userSelectedMinTemperature: null,
      userSelectedMaxTemperature: null
    });
  }

  const handleOnRemoveImageOnIndex = (index) => {
    var auxImageList = state.images.map(image => ({ ...image }));
    var auxTemperatureList = state.temperatures.map(temperature => ({ ...temperature }));

    auxImageList.splice(index, 1);
    auxTemperatureList.splice(index, 1);

    setState({
      ...state,
      images: auxImageList,
      temperatures: auxTemperatureList
    })
  }

  const handleOnMoveImageToTop = (index) => {
    var auxImageList = state.images.map(image => ({ ...image }));
    var auxTemperatureList = state.temperatures.map(temperature => ({ ...temperature }));
    var image = { ...auxImageList[index] }
    var temperature = { ...auxTemperatureList[index] }

    auxImageList.splice(index, 1);
    auxTemperatureList.splice(index, 1);

    auxImageList.push(image);
    auxTemperatureList.push(temperature);

    setState({
      ...state,
      images: auxImageList,
      temperatures: auxTemperatureList
    })
  }

  const handleSaveManipulation = (finalImage, palleteName) => {
    setLoading(true);

    userPassService
      .saveScreeningGroupAnalysedImage({
        groupId: group.groupId,
        screeningId: screeningId,
        palleteName: palleteName,
        originImages: addedImagesIds,
        image: finalImage,
      })
      .then(() => {
        toast.success("Análise termográfica salva com sucesso");
        setState(
          {
            ...state,
            images: [],
            temperatures: []
          }
        );
        handleGetModalInfo();
      })
      .catch(() => {
        setLoading(false);
        toast.error("Falha ao salvar análise termográfica");
      });
  };

  const toggleLeft = (bool) => {
    setIsGalleryLeft(bool);
  }

  const toggleRight = (bool) => {
    setIsDiagnosisRight(bool);
  }

    const updateDeltaTemperatures = useCallback((updatedDeltas) => {
        setDeltas((prevDeltas) => 
            prevDeltas.map((delta, index) => {
                const update = updatedDeltas[index];
                return update ? { ...delta, ...update } : delta;
            })
        );
    })

    const updateDeltaProps = useCallback((areaIndex, areaKey, props) => {
        if (areaKey == 'first') {
            setDeltas((prevDeltas) => 
                prevDeltas.map((delta, index) =>
                    index === areaIndex ? {
                        ...delta,
                        firstAreaMaxTemperature: 0,
                        firstAreaMinTemperature: 0,
                        firstAreaAvgTemperature: 0,
                        firstAreaProps: props,
                    } : delta
                )
            );
        } else {
            setDeltas((prevDeltas) => 
                prevDeltas.map((delta, index) =>
                    index === areaIndex ? {
                        ...delta,
                        secondAreaMaxTemperature: 0,
                        secondAreaMinTemperature: 0,
                        secondAreaAvgTemperature: 0,
                        secondAreaProps: props,
                    } : delta
                )
            );
        }
    })

  return (
    <GenericModal
      title="Análise termográfica"
      setShowModal={(value) => {
        if (state.images.length) {
          setIsClosingDiagnosis(true);
          setShowModalConfirm(true);
          return;
        }

        setIsClosingDiagnosis(false);
        setShowModal(value);
      }}
    >
      {showModalConfirm ? (
        <ModalConfirm
          setShowModal={setShowModalConfirm}
          confirm={() => {
            if (isClosingDiagnosis) {
              setShowModal(false);
              return;
            }
            setIsViewingSavedImage(true);
          }}
        />
      ) : null}

      <LeftContainer expandLeft={isGalleryLeft} isDiagnosisRight={isDiagnosisRight}>
        {isGalleryLeft ? (
          <Gallery
            handleImageClick={handleImageClick}
            groupImages={group?.groupImages || []}
            handleGetImageByID={handleGetImageByID}
            handleGetAnalysedImageInfo={handleGetAnalysedImageInfo}
            addedImagesIds={addedImagesIds}
            setSavedImage={setSavedImage}
            setIsViewingSavedImage={setIsViewingSavedImage}
            setShowModalConfirm={setShowModalConfirm}
            groupAnalysedImages={groupAnalysedImages}
            toggleLeft={toggleLeft}
            isShowingDeltaCalc={deltas.length > 0}
            setIsShowingDeltaCalc={setIsShowingDeltaCalc}
            setFormRect={setFormRect}
            formRect={formRect}
            setIsSelectedForm={setIsSelectedForm}
            isSelectedForm={isSelectedForm}
            palette={true}
            paletteOptions={paletteOptions}
            selectedPalette={state.userSelectedPallete}
            setSelectedPalette={ (pallete) => { setState({ ...state, userSelectedPallete: pallete }) } }
            addNewDelta={() => setDeltas( prevItems => [
                ...prevItems,
                {
                    firstAreaProps: null,
                    secondAreaProps: null,
                    firstAreaMaxTemperature: 0,
                    firstAreaMinTemperature: 0,
                    firstAreaAvgTemperature: 0,
                    secondAreaMaxTemperature: 0,
                    secondAreaMinTemperature: 0,
                    secondAreaAvgTemperature: 0
                }
            ])}
            clearDelta={() => setDeltas([])}
          />
        ) : (
          <ArrowContainer onClick={() => { 
            toggleLeft(true);
          }}>
            <ActionIconLeft src={DoubleArrow} />
          </ArrowContainer>
        )}
      </LeftContainer>

      <MiddleContainer>
        {toggleComponent ? (
          <ThermalManipulation
            trRef={trRef}
            imagesOnCanvas={state.images}
            imagesTemperaturesOnCanvas={state.temperatures}
            paletteOptions={paletteOptions}
            savedImage={savedImage}
            isViewingSavedImage={isViewingSavedImage}
            handleSaveManipulation={handleSaveManipulation}
            selectedPaletteMenu={state.userSelectedPallete}
            isShowingDeltaCalcMenu={isShowingDeltaCalc}
            formRectMenu={formRect}
            isSelectedFormMenu={isSelectedForm}
            setIsShowingDelta={setIsShowingDeltaCalc}
            currentRangeValue={[state.minTemperature, state.maxTemperature]}
            userSelectedRangeValue={[state.userSelectedMinTemperature, state.userSelectedMaxTemperature]}
            onUserSelectedRangeValue={ handleOnUserSelectedRangeValue }
            onClear={handleOnClear}
            onRemoveImageOnIndex={handleOnRemoveImageOnIndex}
            onMoveImageToTop={handleOnMoveImageToTop}
            deltas={deltas}
            clearDelta={() => setDeltas([])}
            updateDeltaTemperatures={updateDeltaTemperatures}
            updateDeltaProps={updateDeltaProps}
          />
        ) : null}
      </MiddleContainer>
      
      <RightContainer expandRight={isDiagnosisRight}>
        {isDiagnosisRight ? (
            <DiagnosisSection
              diagnosisData={diagnosisData}
              updateListGroupDiagnostics={updateListGroupDiagnostics}
              toggleRight={toggleRight}
              isAdmin={isAdmin}
            />
        ) : (
          <div>
            <ArrowContainer onClick={() => { 
              toggleRight(true);
            }}>
              <ActionIconRight src={DoubleArrow} />
            </ArrowContainer>
          </div>
        )}
      </RightContainer>
    </GenericModal>
  );
}

export default ModalDiagnosis;
